import { Component, Input, ViewEncapsulation, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'sn-arrow-date-selector',
    templateUrl: './arrow-date-selector.component.html',
    styleUrls: ['./arrow-date-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FormsModule],
})
export class ArrowDateSelectorComponent implements AfterViewInit {
  @Output() onDateChange = new EventEmitter<DateTime>();

  @Input() allowPrevious: boolean = false;

  private arrowButtonClicked = new Subject<void>();

  _selectedDate: DateTime = DateTime.now();
  dateDisplayString: string = this.getDateDisplay();

  @Input()
  get selectedDate(): DateTime {
    return this._selectedDate;
  }
  set selectedDate(value: DateTime) {
    if(this.selectedDate != value && value != null) {
      this._selectedDate = value;
    }
  }

  constructor() { }

  dateDisplaySubscribe() {
    this.arrowButtonClicked.pipe(
        debounceTime(100),
        tap(() => {
          this.onDateChange.emit(this.selectedDate);
        })
    )
    .subscribe();
  }

  ngAfterViewInit() {
    this.dateDisplaySubscribe();
  }

  modifyDay(day: any) {
    this.selectedDate = this.selectedDate.plus({ days: day });
    this.dateDisplayString = this.getDateDisplay();
    this.arrowButtonClicked.next();
  }

  getDateDisplay() {
    if (this.selectedDate.toFormat("MM/dd/yyyy") == DateTime.now().toFormat("MM/dd/yyyy")) {
      return "Today";
    }

    if (this.selectedDate.toFormat("MM/dd/yyyy") == DateTime.now().plus({ days: 1 }).toFormat("MM/dd/yyyy")) {
      return "Tomorrow";
    }

    return this.selectedDate.toFormat("MM/dd/yyyy");
  }
}
