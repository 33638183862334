<h4 mat-dialog-title>Select Receiving Location</h4>
<mat-dialog-content class="overflow-hidden">
  <form #locationSelectForm="ngForm" autocomplete="off">
    <mat-form-field [class.hidden]="receivingLocations.length == 0 || showAll" class="w-full">
      <mat-label>Select Receiving Location</mat-label>
      <input matInput placeholder="Search for Location" name="searchTerm" [(ngModel)]="searchTerm" [matAutocomplete]="auto" #input #searchInputReceiving="ngModel">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="locationSelected($event.option.value)" [classList]="'location-panel'" class="max-h-[320px]">
        <!-- Saved Locations -->
        <div>
          <h1 class="location-header mt-1">Saved Locations</h1>
          @if (isLoading) {
            <mat-option>
              <div class="loading-ellipsis"><div></div><div></div><div></div><div></div></div>
            </mat-option>
          }
          @if (!isLoading) {
            @for (location of filteredLocations; track location; let i = $index) {
              <mat-option class="location-item multiline-mat-option" [value]="location">
                <div class="text-base">{{ locationNameDisplay(location) }}</div>
                <div class="saved-item">
                  <i class="fa-solid fa-location-dot"></i>
                  <div>{{ locationAddressDisplay(location) }}</div>
                </div>
                <div class="saved-item">
                  <i class="fa-solid fa-building"></i>
                  <div>{{ location.customerName }}</div>
                </div>
                @if (location?.locationContacts?.length > 1) {
                  <div class="saved-item">
                    <i class="fa-solid fa-user-group"></i>
                    <div class="text-gray-500">Multiple Contacts</div>
                  </div>
                }
                @if (location?.locationContacts?.length <= 1 && location.name) {
                  <div class="saved-item">
                    <i class="fa-solid fa-user"></i>
                    <div class="text-gray-500">{{ locationContactNameDisplay(location)}}</div>
                  </div>
                }
              </mat-option>
            }
          }
          @if (!isLoading && filteredLocations.length <= 0) {
            <div class="ml-4">
              No Receiving Location Found
            </div>
          }
          <mat-option class="h-0"></mat-option>
        </div>
      </mat-autocomplete>
      <mat-hint class="text-sm">Location not found? <a class="link" (click)="showAll = true;">Search Locations</a></mat-hint>
    </mat-form-field>
    @if (showAll) {
      <sn-location-selector [features]="selectorFeatures" (locationSelected)="locationSelected($event);" [customerID]="selectedCustomerID" [locationID]="selectedLocationID"></sn-location-selector>
    }
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="dialog-footer-buttons">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="!selectedLocationID" class="dialog-footer-buttons"
  (click)="onOkClick()">Set</button>
</mat-dialog-actions>
