import { MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { HostListener, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LocationDto, LocationsClient, LocationSearchDto, LocationWithContactsDto } from '../../core/services/api.service';
import { UserResolverService } from '../../core/services/user-resolver.service';
import { RequestService } from '../../core/services/request.service';
import { LocationHelper } from '../../core/locations-helper';
import { ConfirmDialogComponent } from '../../../app/shared/confirm-dialog/confirm-dialog.component';
import { debounceTime, distinctUntilChanged, of, Subscription, switchMap } from 'rxjs';
import { NgModel, FormsModule } from '@angular/forms';
import { LocationSelectFeatures, LocationSelectorDto, LocationSelectorComponent } from '../widgets/location-selector/location-selector.component';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'sn-location-select-dialog',
    templateUrl: './location-select-dialog.component.html',
    styleUrls: ['./location-select-dialog.component.scss'],
    standalone: true,
    imports: [
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    LocationSelectorComponent,
    MatButtonModule
],
})
export class LocationSelectDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInputReceiving', { static: true })searchInput: NgModel | undefined;

  selectedCustomerID: number | undefined;
  selectedLocation: LocationDto | undefined;
  selectedLocationName: string = "";
  receivingLocations: LocationSearchDto[] = [];
  filteredLocations: LocationSearchDto[] = [];
  selectedLocationID: number | undefined;
  showAll: boolean = false;
  isASMUser: boolean = false
  searchTerm: string = "";
  isLoading: boolean = false;
  snSearchSubscription: Subscription | undefined;
  selectorFeatures: LocationSelectFeatures = LocationSelectFeatures.Add | LocationSelectFeatures.SearchStatusNow | LocationSelectFeatures.ShowQuickAdd;

  constructor(
    public dialogRef: MatDialogRef<LocationSelectDialogComponent>,
    public requestService: RequestService,
    public locationsClient: LocationsClient,
    public matDialog: MatDialog,
    protected _userResolverService: UserResolverService
  ) {
    _userResolverService.userInfo.subscribe(x => {
      if(x.IsASM) {
        this.selectedCustomerID = 0;
        this.isASMUser = true;
      }
      else {
        this.selectedCustomerID = x.CustomerId;
      }
    });
  }

  ngAfterViewInit(): void {
    this.bindSearch();
  }

  ngOnInit(): void {
    this.loadReceivingLocations();
  }

  bindSearch() {
    this.snSearchSubscription = this.searchInput?.valueChanges?.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap(() => {
        if(this.searchTerm?.length > 3) {
          this.isLoading = true;
          const searchTermLowerCase = this.searchTerm.toLowerCase();
          this.filteredLocations = this.receivingLocations.filter(s =>
            s.address1?.toLocaleLowerCase().includes(searchTermLowerCase)
            || s.name?.toLowerCase().includes(searchTermLowerCase))
          this.isLoading = false;
          return this.filteredLocations;
        } else {
          this.filteredLocations = this.receivingLocations;
          return of();
        }
      })
    )
    .subscribe(results => {
      this.isLoading = false;
    })
  }

  loadReceivingLocations() {
    this.isLoading = true;
    this.requestService.get(this.locationsClient.locations_GetReceivingLocations(this.selectedCustomerID), x => {
      this.isLoading = false;
      this.receivingLocations = x;
      this.filteredLocations = x;
      if(!x || x.length == 0) {
        this.showAll = true;
      }
    }, "Receiving Locations");
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  locationSelected(selection: any) {
    if(selection) {
      if(selection.location) {
        this.selectedLocation = selection.location;
        this.selectedLocationID = selection.location.id;
      }
      else {
        this.selectedLocation = selection;
        this.selectedLocationID = selection.id;
      }

      if(!this.selectedLocation?.isReceivingLocation) {
        const confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
          width: '375px',
          data: {
            title: "Mark as Receiving Location?",
            message: "Do you want to mark this location as a receiving location for easy access in the future?",
            displayMessageAsHTML: false,
            okButtonText: "Yes",
            okButtonColor: 'primary',
            cancelButtonText: "No"
          },
          disableClose: true
        });

        confirmDialogRef.afterClosed().subscribe(result => {
          if(result && this.selectedLocationID) {
            this.requestService.save(this.locationsClient.locations_SetAsReceivingLocation(this.selectedLocationID), x => {this.loadReceivingLocations()}, "Receiving Locations");
            this.showAll = false;
            this.selectedLocationName = this.selectedLocation?.name ?? ""
          }
        });
      }
    } else {
      delete this.selectedLocation;
      delete this.selectedLocationID;
      this.selectedLocationName = "";
    }
  }

  onOkClick(): void {
    if(this.selectedLocation) {
      this.dialogRef.close(this.selectedLocation);
    }
    else {
      this.dialogRef.close(null);
    }
  }

  locationAddressDisplay(location: LocationWithContactsDto): string {
    return LocationHelper.locationAddressDisplay(location);
  }

  locationNameDisplay(location: LocationWithContactsDto): string {
    return LocationHelper.getLocationOrPrimaryContactDisplayName(location);
  }

  locationContactNameDisplay(location: LocationWithContactsDto): string {
    return LocationHelper.getLocationPrimaryContactName(location);
  }

  displayFn(location: any) {
    if (location) { return location.name; }
  }
}
